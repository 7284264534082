import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.BodyText,
  MessageSeenText: Color.MutedText,
  PaginationText: Color.BodyText,
  BorderColor: Color.Surface.Base.Dimmed,
  Highlight: Color.Primary + '20',
  HighlightBorder: Color.Primitive.Primary,
};
