import type { LogConfig } from './common';
import { logConfig } from './common';
import type { Site } from './market';

export type GameCategory = 'all' | 'slots' | 'bingo' | 'table' | 'poker' | 'betting';
export type BettingProvider = 'kambi' | 'scoutgaming' | 'colossusbets';

export type ClientConfig = LogConfig & {
  applePayScriptUrl?: string;
  baseUrl: string;
  helpdeskUrl: string;
  domain: string;
  gameCategories: GameCategory[];
  bettingProviders: BettingProvider[];
  gtmId: string;
  newKycUploadBaseUrl: string;
  sentryDSN?: string;
  debugSentry?: string;
  serverLocalUrl: string;
  version?: string;
  sanityProjectId: string;
  sanityDataset: string;
  pafSiteName: string;
  companyName: string;
  companySiteName: string;
  maxBetValue?: string;
  gameOfChanceAgeRequirement?: number;
  hasPayAndPlay: boolean;
  hasSignicatLogin: boolean;
  disableSSR: boolean;
  cloudflareSiteKey?: string;
  siteHasLoyaltyProgram: boolean;
  siteUsesRewardOnlyBonusTitles: boolean;
  p3PaymentEnabled: boolean;
  p3PaymentEnabledSites: string[];
  multisiteRegistrationEnabled: boolean;
  kreditzBaseUrl?: string;
  verifyPhoneNumber: boolean;
  enableOpenSearch: boolean;
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const clientConfig: ClientConfig = {
  ...logConfig,
  gameCategories: (process.env.GAME_CATEGORIES?.split(',') ?? []) as ClientConfig['gameCategories'],
  bettingProviders: (process.env.BETTING_PROVIDERS?.split(',') ?? []) as ClientConfig['bettingProviders'],
  baseUrl: process.env.BASE_URL!,
  helpdeskUrl: process.env.HELPDESK_URL!,
  domain: process.env.DOMAIN!,
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID!,
  newKycUploadBaseUrl: process.env.NEW_KYC_UPLOAD_BASE_URL!,
  sentryDSN: process.env.SENTRY_DSN,
  debugSentry: process.env.DEBUG_SENTRY,
  serverLocalUrl: process.env.SERVER_LOCAL_URL!,
  version: process.env.VERSION,
  sanityProjectId: process.env.SANITY_PROJECT_ID!,
  sanityDataset: process.env.SANITY_DATASET!,
  pafSiteName: process.env.PAF_SITE_NAME!,
  applePayScriptUrl: process.env.APPLE_PAY_SCRIPT_URL,
  companyName: process.env.COMPANY_NAME!,
  companySiteName: process.env.COMPANY_SITE_NAME!,
  maxBetValue: process.env.MAX_BET_VALUE,
  gameOfChanceAgeRequirement:
    process.env.GAME_OF_CHANCE_AGE_REQUIREMENT == null
      ? undefined
      : parseInt(process.env.GAME_OF_CHANCE_AGE_REQUIREMENT, 10),
  hasPayAndPlay: process.env.PAY_AND_PLAY_ENABLED === 'true',
  hasSignicatLogin: process.env.SIGNICAT_ENABLED === 'true',
  disableSSR: process.env.DISABLE_SSR === 'true',
  cloudflareSiteKey: process.env.CLOUDFLARE_SITEKEY,
  siteHasLoyaltyProgram: process.env.SITE_HAS_LOYALTY_PROGRAM === 'true',
  siteUsesRewardOnlyBonusTitles: process.env.SITE_USES_REWARD_ONLY_BONUS_TITLES === 'true',
  p3PaymentEnabled: process.env.P3_PAYMENT_ENABLED === 'true',
  p3PaymentEnabledSites: (process.env.P3_PAYMENT_ENABLED_SITES?.split(',') ?? []) as Site[],
  multisiteRegistrationEnabled: process.env.MULTISITE_REGISTRATION_ENABLED === 'true',
  kreditzBaseUrl: process.env.KREDITZ_BASE_URL,
  verifyPhoneNumber: process.env.VERIFY_PHONE_NUMBER === 'true',
  enableOpenSearch: process.env.OPEN_SEARCH_ENABLED === 'true',
};

declare global {
  interface Window {
    pafAppConfig: ClientConfig;
  }
}

export const getClientConfig = () => {
  const isServer = typeof window === 'undefined';
  return isServer ? clientConfig : window.pafAppConfig;
};
