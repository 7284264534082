import type { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { BaseFont, deviceWithHover } from '@pafcloud/style';
import { MainMenuColors, MainMenuFontStyles } from './main-menu-colors';

export const MainMenuItem = styled.li<HTMLAttributes<HTMLLIElement> & { active?: boolean }>(
  {
    position: 'relative',
    minHeight: 56,
    padding: 0,
    borderBottom: MainMenuColors.ItemDivider,
    fontFamily: BaseFont.BrandFont,
    textTransform: MainMenuFontStyles.TextTransform,
    textShadow: MainMenuColors.TextShadow,
    color: MainMenuColors.Text,
    WebkitTapHighlightColor: 'transparent',

    [deviceWithHover]: {
      ':hover': {
        color: MainMenuColors.TextHover,
        background: MainMenuColors.BackgroundHover,
      },
    },

    ':focus-within': {
      background: MainMenuColors.BackgroundHover,
      color: MainMenuColors.TextHover,
    },
  },
  ({ active }) =>
    active && {
      color: MainMenuColors.TextActive,
      background: MainMenuColors.BackgroundActive,
      boxShadow: MainMenuColors.ItemActiveShadow,

      // Do not set a background to selected submenu item
      'li ul &': {
        background: 'unset',
      },

      [deviceWithHover]: {
        ':hover': {
          color: MainMenuColors.TextActive,
          background: MainMenuColors.BackgroundHover,
        },
      },
    },
);
