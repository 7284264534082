import type { ComponentType } from 'react';
import type { AppContext, AppProps } from 'next/app';
import { fetchQuery } from 'react-relay/hooks';
import type { GraphQLTaggedNode, OperationType, Variables } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { logger } from '@pafcloud/logging';
import { ConfigProvider } from '@pafcloud/contexts';
import { getClientConfig } from '@pafcloud/config';
import { getI18n, getInitiatedI18n, I18nextProvider, initClientTranslations, namespaces } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { initRelayEnvironment } from './initRelayEnvironment';
import type { AppWithQueryData, WithPage } from './PageWithData';
import { HydratedRelayEnvironmentProvider } from './HydratedRelayEnvironmentProvider';
import { getAllowedSSRCookie } from './getCookie';
import { RelayDataProvider } from './RelayDataProvider';

type RelayProps = {
  queryArguments: Variables;
  records?: RecordMap;
  statusCode: number;
};

type TranslationProps = {
  translations: Record<string, string>;
};

type InitialProps = {
  initialProps: Record<string, unknown>;
};

type RelayDataProvider = ComponentType<WithPage<AppProps> & Omit<RelayProps, 'records' | 'statusCode'> & InitialProps>;

type WithDataComponent = ComponentType<WithPage<AppProps> & RelayProps & TranslationProps & InitialProps> & {
  getInitialProps(context: WithPage<AppContext>): Promise<RelayProps & TranslationProps & InitialProps>;
};

type WithDataOptions<T extends OperationType> = {
  App: AppWithQueryData<T>;
  ErrorPage: ComponentType;
  NotFoundPage: ComponentType;
  query: GraphQLTaggedNode;
  queryArguments: T['variables'];
};

const isServer = typeof window === 'undefined';

export const withData = <T extends OperationType>(options: WithDataOptions<T>) => {
  const WithDataComponent: WithDataComponent = (props) => {
    if (!isServer) {
      initClientTranslations($buildEnv.site, props.translations);
    }

    const i18n = getI18n($buildEnv.site, props.router.locale);

    if (props.statusCode === 404) {
      return (
        <I18nextProvider i18n={i18n}>
          <ConfigProvider config={getClientConfig()}>
            <options.NotFoundPage />
          </ConfigProvider>
        </I18nextProvider>
      );
    }

    if (props.statusCode === 500 || props.records == null) {
      return (
        <I18nextProvider i18n={i18n}>
          <ConfigProvider config={getClientConfig()}>
            <options.ErrorPage />
          </ConfigProvider>
        </I18nextProvider>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        <HydratedRelayEnvironmentProvider records={props.records}>
          <RelayDataProvider query={options.query} queryArguments={options.queryArguments}>
            {(app) => (
              <options.App queryData={app.data} isLoadingClientData={app.isLoadingClientData}>
                {(() => {
                  if (props.Component.query == null) {
                    return <props.Component pageData={{}} {...props.initialProps} />;
                  }

                  // If the page has a query, we need to read it,
                  // and possibly refetch it with personalized data.
                  return (
                    <RelayDataProvider query={props.Component.query} queryArguments={props.queryArguments}>
                      {(page) => <props.Component pageData={page.data} {...props.initialProps} />}
                    </RelayDataProvider>
                  );
                })()}
              </options.App>
            )}
          </RelayDataProvider>
        </HydratedRelayEnvironmentProvider>
      </I18nextProvider>
    );
  };

  WithDataComponent.getInitialProps = async ({ ctx, router, Component }) => {
    const initialPageProps = await Component.getInitialProps?.(ctx);

    const { queryArguments = {}, postQuery, ...initialProps } = initialPageProps ?? {};

    const translations: Record<string, string> = {};

    if (isServer) {
      const i18n = await getInitiatedI18n($buildEnv.site, router.locale);

      // Pass translations to the client - we are currently unable to only pass relevant namespaces.
      namespaces.forEach((namespace) => {
        translations[namespace] = i18n.getResourceBundle(i18n.language, namespace);
      });
    }

    try {
      // This must know nothing about the client.
      // Don't pass in any other headers, cookies, or anything here that is specific to the player.
      const environment = initRelayEnvironment({
        headers: {
          cookie: getAllowedSSRCookie(ctx.req),
        },
      });

      if (isServer) {
        // We need to fetch the app query on the server to hydrate the relay store.
        // But subsequent page changes that happens on the client doesn't need to refetch it.
        await new Promise<void>((resolve, reject) => {
          fetchQuery<T>(environment, options.query, options.queryArguments, {
            networkCacheConfig: {
              metadata: {
                language: router.locale ?? router.defaultLocale,
              },
            },
          }).subscribe({
            error(reason: unknown) {
              logger.error('Could not load app query', { error: reason });
              reject();
            },
            complete() {
              resolve();
            },
          });
        });
      }

      if (Component.query) {
        const pageQuery = Component.query;

        await new Promise<void>((resolve, reject) => {
          fetchQuery<T>(environment, pageQuery, queryArguments, {
            networkCacheConfig: {
              metadata: {
                language: router.locale ?? router.defaultLocale,
              },
            },
          }).subscribe({
            next: postQuery,
            error(reason: unknown) {
              logger.error('Could not load page query', { error: reason });
              reject();
            },
            complete() {
              resolve();
            },
          });
        });
      }

      return {
        initialProps,
        queryArguments,
        records: environment.getStore().getSource().toJSON(),
        statusCode: ctx.res?.statusCode ?? 200,
        translations,
      };
    } catch {
      if (ctx.res) {
        ctx.res.statusCode = 500;
      }

      return {
        initialProps,
        queryArguments,
        statusCode: 500,
        translations,
      };
    }
  };

  return WithDataComponent;
};
