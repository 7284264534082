import Head from 'next/head';
import { graphql } from 'relay-runtime';
import { withData } from '@pafcloud/with-data';
import { useTranslation } from '@pafcloud/i18n';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { App as PafApp } from './app/index';
import type { AppQuery } from './__generated__/AppQuery.graphql';
import { ErrorPage } from './ErrorPage';
import { NotFoundPage } from './NotFoundPage';
import { useCanonical } from './useCanonical';
import { config } from './config';

// We need to force the app-assets to be served by nextjs.
// Without it they get tree-shaken since they are only used on the server.
import '@pafcloud/app-assets';

export const App = withData<AppQuery>({
  ErrorPage,
  NotFoundPage,

  query: graphql`
    query AppQuery {
      ...App_data
    }
  `,
  queryArguments: {},

  App({ children, queryData, isLoadingClientData }) {
    const { t } = useTranslation('common');
    const canonicalURL = useCanonical(config.baseUrl);
    const isSSR = useIsSSR();

    if (config.disableSSR && isSSR) {
      return null;
    }

    return (
      <>
        <Head>
          {canonicalURL && <link rel="canonical" key="canonical" href={canonicalURL} />}
          <title>{t('default-title')}</title>
        </Head>
        <PafApp config={config} data={queryData} isLoadingClientData={isLoadingClientData}>
          {children}
        </PafApp>
      </>
    );
  },
});
