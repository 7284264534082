import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const PinataBrandColors = {
  // Primary
  Primary: '#533f86',
  PrimaryLight: '#7463ac',
  PrimaryHighlight: '#9284be',

  // Alternate
  Alternate: '#fcee30',
  AlternateLight: '#ffef6c',
  AlternateHighlight: '#fdf695',

  // Accent
  Pink: '#ed1174',

  Aqua: '#3ec1cf',
  AquaLight: '#7ed9e3',
  AquaSuperLight: '#e7f6f8', // AquaTransparent15 on white returns this color
  AquaDark: '#098398',
  AquaBlack: '#0d2c2d',

  // Neutral
  Neutral: '#798486',
  NeutralLighter: '#c8d8db',
  NeutralLight: '#a7b4b6',
  NeutralDark: '#43494a',
  NeutralBlack: '#24292a',

  Black: '#000000',
  White: '#ffffff',

  // Extra
  Aux: '#FF206E',
};

const Primitive: PrimitiveColorType = {
  Primary: PinataBrandColors.Primary,
  PrimaryTint: PinataBrandColors.PrimaryLight,
  PrimaryContrast: PinataBrandColors.White,

  Secondary: PinataBrandColors.Alternate,
  SecondaryTint: PinataBrandColors.AlternateLight,
  SecondaryContrast: PinataBrandColors.NeutralBlack,

  Accent: PinataBrandColors.AquaDark,
  AccentTint: PinataBrandColors.Aqua,
  AccentContrast: PinataBrandColors.White,

  Gradient: PinataBrandColors.Primary,
  GradientTint: PinataBrandColors.PrimaryLight,
  GradientContrast: PinataBrandColors.White,
};

const TextColors: TextColorType = {
  HeadingText: PinataBrandColors.Primary,
  BodyText: PinataBrandColors.Black,
  MutedText: PinataBrandColors.Neutral,
  HighlightedText: PinataBrandColors.AquaBlack,
  LinkText: PinataBrandColors.Primary,
  ErrorText: PinataBrandColors.Pink,
  DisabledText: PinataBrandColors.NeutralLight,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: PinataBrandColors.White,
    Foreground: PinataBrandColors.Black,
    Dimmed: createTransparentColor(PinataBrandColors.AquaLight, 0.5),
  },
  Nested: {
    Background: PinataBrandColors.AquaSuperLight,
    Foreground: PinataBrandColors.AquaBlack,
    Dimmed: createTransparentColor(PinataBrandColors.Aqua, 0.3),
  },
  Disabled: {
    Background: PinataBrandColors.NeutralLighter,
    Foreground: PinataBrandColors.Neutral,
    Dimmed: createTransparentColor(PinataBrandColors.NeutralDark, 0.15),
  },
  Floating: {
    Background: PinataBrandColors.White,
    Foreground: PinataBrandColors.Black,
    Dimmed: createTransparentColor(PinataBrandColors.Primary, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: PinataBrandColors.Primary,
  SuccessContrast: PinataBrandColors.White,

  Info: PinataBrandColors.AquaLight,
  InfoContrast: PinataBrandColors.Black,

  Attention: PinataBrandColors.Alternate,
  AttentionContrast: PinataBrandColors.Black,

  Danger: PinataBrandColors.Aux,
  DangerContrast: PinataBrandColors.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ElevationType = {
  Level1: `
    0 0 0 1px ${createTransparentColor(PinataBrandColors.AquaLight, 0.5)},
    0 2px 5px 0 var(--shadow-color, ${createTransparentColor(PinataBrandColors.Primary, 0.4)})
  `,
  Level2: `0 3px 8px -2px var(--shadow-color, ${createTransparentColor(PinataBrandColors.Primary, 0.3)})`,
  Level3: `0 8px 12px -2px var(--shadow-color, ${createTransparentColor(PinataBrandColors.Primary, 0.2)})`,
};

const General: GeneralType = {
  Bonus: PinataBrandColors.Pink,
  BonusContrast: PinataBrandColors.White,
};

const Layout: LayoutColors = {
  Background: PinataBrandColors.AquaSuperLight,
  BrandBorder: Primitive.Primary,
  BrowserTheme: Primitive.AccentTint,
  LoadingColor: Primitive.Primary,
  Overlay: PinataBrandColors.AquaBlack + 'C0',
  Sidebar: {
    Background: `linear-gradient(180deg, ${Primitive.Accent}, ${Primitive.AccentTint})`,
    Foreground: PinataBrandColors.White,
  },
};

const Hero: HeroColorType = {
  Heading: PinataBrandColors.White,
  Text: PinataBrandColors.White,
  TextHighlight: PinataBrandColors.White,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color = {
  ...PinataBrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: PinataBrandColors.AquaSuperLight,
  Gradient: `linear-gradient(45deg, ${PinataBrandColors.AquaDark}, ${PinataBrandColors.AquaLight})`,

  // Alternate Text Colors
  HeadingTextAlternate: PinataBrandColors.White,
  BodyTextAlternate: PinataBrandColors.White,
  LinkTextAlternate: PinataBrandColors.Alternate,

  // Shadows, highlight and transparency
  Shadow: createTransparentColor(PinataBrandColors.Primary, 0.4),
  ShadowInset: createTransparentColor(PinataBrandColors.White, 0.4),
  FocusHighlight: createTransparentColor(PinataBrandColors.AquaDark, 0.5),
  AquaTransparent15: createTransparentColor(PinataBrandColors.Aqua, 0.15),
  AquaTransparent50: createTransparentColor(PinataBrandColors.Aqua, 0.5),
  AquaLightTransparent50: createTransparentColor(PinataBrandColors.AquaLight, 0.5),
  AquaDarkTransparent50: createTransparentColor(PinataBrandColors.AquaDark, 0.5),
  NeutralLightTransparent30: createTransparentColor(PinataBrandColors.NeutralLight, 0.3),
  PrimaryTransparent50: createTransparentColor(PinataBrandColors.Primary, 0.5),

  // Theme color for mobile browsers
  BrowserTheme: PinataBrandColors.Primary,

  // Dividers
  Divider: PinataBrandColors.Primary,
  DividerAlternate: PinataBrandColors.White,

  // Validation and notification colors
  Success: PinataBrandColors.Primary,
  Warning: PinataBrandColors.Alternate,
  Error: PinataBrandColors.Pink,

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart}, ${ColorEnd})`,
  AdaptiveBackground: `linear-gradient(90deg, ${PinataBrandColors.Primary}, ${PinataBrandColors.Primary})`,
};
